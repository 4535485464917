import { postJson } from "./common"

export async function createScript({ appId, scriptName, scriptType }) {
  const resJson = await postJson('/script/create', { appId, scriptName, scriptType });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}

export async function deleteScript({ scriptId }) {
  await postJson('/script/delete', { scriptId });
}

export async function updateScript({ scriptId, content }) {
  const resJson = await postJson('/script/update', { scriptId, content });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}

export async function revertScript({ scriptId, changeId }) {
  const resJson = await postJson('/script/revert', { scriptId, changeId });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}

export async function listScripts({ appId, scriptType }) {
  const resJson = await postJson('/script/list', { appId, scriptType });
  if (!resJson.scripts) throw Error('Malformed response from backend');
  return resJson.scripts;
}

export async function scriptInfo({ scriptId }) {
  const resJson = await postJson('/script/info', { scriptId });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}

export async function createFolder({ appId, folderName, folderType }) {
  const resJson = await postJson('/script/create-folder', { appId, folderName, folderType });
  if (!resJson.folder) throw Error('Malformed response from backend');
  return resJson.folder;
}

export async function deleteFolder({ folderId }) {
  await postJson('/script/delete-folder', { folderId });
}

export async function listFolders({ appId, folderType }) {
  const resJson = await postJson('/script/list-folders', { appId, folderType });
  if (!resJson.folders) throw Error('Malformed response from backend');
  return resJson.folders;
}

export async function moveToFolder({ scriptId, folderId }) {
  const resJson = await postJson('/script/move-to-folder', { scriptId, folderId });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}

export async function aiGenerate({ scriptId, description }) {
  const resJson = await postJson('/script/ai-generate', { scriptId, description });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}

export async function aiAdjust({ scriptId, adjustment }) {
  const resJson = await postJson('/script/ai-adjust', { scriptId, adjustment });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}