<template>
  <div class="full center bg-med">
    <div class="card">
      <div class="card-header">
        <h1>My Apps</h1>
        <p class="text-secondary">Below is a list of all of your applications.</p>
      </div>
      <div class="app-list">
        <div class="app-list-item" v-for="app of apps" :key="app.id"
          @click.prevent="openModal('app-info', { app })">
          <div class="app-icon">
            <img src="@/assets/icons/app.png" alt="App Icon" class="app-icon-image">
          </div>
          <div class="app-info">
            <h3>{{ app.name }}</h3>
            <p class="text-secondary">{{ app.description }}</p>
          </div>
          <img src="@/assets/icons/close.png" alt="Delete" class="delete-icon" 
            @click.prevent.stop="openModal('delete-app', { app })">
        </div>
        <div class="app-list-item create-app" @click.prevent="openModal('create-app')" v-if="!isLoading">
          <div class="app-icon">
            <img src="@/assets/icons/create.png" alt="App Icon" class="app-icon-image">
          </div>
          <div class="app-info">
            <h3>Create App</h3>
            <p class="text-secondary">Create a new application</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <CreateAppModal v-if="modal.id == 'create-app'" />
            <AppInfoModal v-else-if="modal.id == 'app-info'" :app="modal.data.app" />
            <DeleteAppModal v-else-if="modal.id == 'delete-app'" :app="modal.data.app" @onAppDeleted="handleAppDeleted" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as appApi from '../api/app.js'
import AppInfoModal from '../modals/apps/AppInfoModal.vue';
import DeleteAppModal from '../modals/apps/DeleteAppModal.vue';
import CreateAppModal from '../modals/apps/CreateAppModal.vue';

export default {
  name: 'AppListView',
  data() {
    return {
      apps: [],
      isLoading: false,
      modal: {
        open: false,
        id: '',
        data: {}
      },
    }
  },
  created() {
    this.attemptFetchApps();
  },
  methods: {
    async attemptFetchApps() {
      try {
        this.apps = [];
        this.isLoading = true;
        this.apps = await appApi.listApps();

      } catch (err) {
        if (err.message == 'Permission denied') {
          this.$router.push('/login');
          return;
        }

        alert('Failed to fetch apps: ' + err.message);
      } finally {
        this.isLoading = false;
      }
    },
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    handleAppDeleted(appId) {
      this.apps = this.apps.filter(app => {
        return app.id != appId;
      })

      this.closeModal();
    }
  },
  components: { CreateAppModal, AppInfoModal, DeleteAppModal }
}
</script>


<style scoped>
.full {
  justify-content: flex-start;
  padding: var(--space-md);
  padding-top: calc(var(--space-lg) + var(--space-md));
  overflow-y: auto;
}

.card {
  max-width: 1000px;
  gap: var(--space-lg);
}

.app-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  min-height: 500px;
}

.app-list-item {
  border: 1px solid var(--border-primary);
  padding: var(--space-md);
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: var(--space-md);
  cursor: pointer;
  background: var(--background-primary);
  transition: var(--transition-base);
}

.app-list-item:hover {
  background: var(--background-secondary);
}

.app-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-secondary);
  border-radius: 2px;
  padding: var(--space-xs);
}

.app-icon-image {
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.8;
}

.app-info {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  flex: 1;
}

.app-info h3 {
  color: var(--text-primary);
  font-size: var(--font-size-lg);
  line-height: 1.2;
}

.app-info p {
  font-size: var(--font-size-base);
  line-height: 1.5;
}

.create-app {
  border: 1px dashed var(--border-primary);
}

.create-app:hover {
  border-style: solid;
}

.create-app .app-icon {
  background: var(--primary-900);
}

.create-app .app-icon-image {
  opacity: 1;
}

.delete-icon {
  width: 20px;
  height: 20px;
  display: block;
  opacity: 0.5;
  transition: var(--transition-fast);
}

.delete-icon:hover {
  opacity: 1;
}

/* Ensure modal transitions work with new theme */
.modal-background {
  background: color-mix(in srgb, var(--gray-900) 95%, transparent);
}

.modal-background .card {
  background: var(--background-secondary);
}
</style>