<template>
  <div class="card" style="max-width: 500px;">
    <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="card-header">
      <h1>Delete {{ scriptTypeDisplay }}</h1>
      <p>Please type <strong>{{ script.name }}</strong> in the box to delete this script.</p>
    </div>
    <div class="card-body">
      <form action="" class="form" @submit.prevent="">
        <!-- Email -->
        <div class="form-group">
          <input type="text" id="email" class="form-control" placeholder=" " v-model="name">
          <label for="email" class="form-label">Script Name</label>
        </div>
        <input type="submit" hidden>
      </form>
    </div>
    <div class="card-footer">
      <button class="button danger" @click="attemptDeleteScript" :disabled="!isNameEntered()">
        <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
        <span v-else>Delete Script</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as scriptApi from '@/api/script.js'

export default {
  name: 'DeleteScriptModal',
  props: {
    scriptTypeDisplay: String,
    scriptType: String,
    script: Object
  },
  data() {
    return {
      isLoading: false,
      name: '',
      error: ''
    }
  },
  methods: {
    async attemptDeleteScript() {
      if (this.isLoading) return;

      try {
        this.error = '';
        this.isLoading = true;

        // One second delay
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        })

        // Attempt to login
        await scriptApi.deleteScript({
          scriptId: this.script.id
        })

        this.$emit('onScriptDeleted', this.script.id);

      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    isNameEntered() {
      const name1 = this.name.replace(/\s+/, '').toLowerCase();
      const name2 = this.script.name.replace(/\s+/, '').toLowerCase();
      return name1 == name2;
    }
  },
  emits: ['onScriptDeleted']
}
</script>

<style scoped></style>