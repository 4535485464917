import { postJson } from "./common"

export async function createApp({ appName, appDescription }) {
  const resJson = await postJson('/app/create', { appName, appDescription });
  if (!resJson.app) throw Error('Malformed response from backend');
  return resJson.app;
}

export async function listApps() {
  const resJson = await postJson('/app/list');
  if (!resJson.apps) throw Error('Malformed response from backend');
  return resJson.apps;
}

export async function appInfo({ appId }) {
  const resJson = await postJson('/app/info', { appId });
  if (!resJson.app) throw Error('Malformed response from backend');
  return resJson.app;
}

export async function deleteApp({ appId }) {
  await postJson('/app/delete', { appId });
}