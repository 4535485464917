<template>
  <div class="card" style="max-width: 700px;">
    <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="card-header">
      <h1>Test Function</h1>
      <p class="text-secondary">Execute the function using your own payload</p>
    </div>

    <div class="card-body">
      <!-- Tab Navigation -->
      <div class="tabs">
        <button :class="['tab-button', { active: activeTab === 'json' }]" @click="activeTab = 'json'">
          JSON Payload
        </button>
        <button :class="['tab-button', { active: activeTab === 'form' }]" @click="activeTab = 'form'">
          Form Data
        </button>
      </div>

      <!-- eslint-disable-next-line vue/no-v-model-argument -->
      <CodeMirror v-if="activeTab == 'json'" v-model:value="payload"
        :options="{ mode: 'application/json', theme: 'dark' }" @input="handleUpdatePayload" height="300px" />
      <FormTable v-else-if="activeTab == 'form'" :value="formRows" @update="handleFormUpdate" />
    </div>

    <div class="card-footer">
      <button class="button" @click="attemptTestFunction">
        <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
        <span v-else>Run</span>
      </button>
      <!-- Error Display -->
      <div v-if="errorData" class="error-container">
        <div class="error-header">
          <span class="error-icon">⚠️</span>
          <span class="error-title">{{ errorData.name || 'Error' }}</span>
        </div>
        <div class="error-message">{{ errorData.message }}</div>
        <div v-if="errorData.stack" class="error-stack">
          <div class="stack-header">Stack Trace:</div>
          <pre>{{ errorData.stack }}</pre>
        </div>
      </div>
      <!-- Dynamic Result Display -->
      <div v-if="result" class="result-container">
        <!-- JSON Response -->
        <div v-if="resultType === 'json'" class="result-box code">
          <pre>{{ JSON.stringify(result, null, 2) }}</pre>
        </div>

        <!-- Plain Text Response -->
        <div v-else-if="resultType === 'text'" class="result-box text">
          {{ result }}
        </div>

        <!-- Image Response -->
        <div v-else-if="resultType === 'image'" class="result-box image">
          <img :src="result" :alt="'Function result'" />
        </div>

        <!-- HTML Response -->
        <div v-else-if="resultType === 'html'" class="result-box html">
          <div v-html="result"></div>
        </div>

        <!-- Binary File Response -->
        <div v-else-if="resultType === 'file'" class="result-box file">
          <div class="file-info">
            <span>File ready for download:</span>
            <a :href="result.url" :download="result.filename" class="button secondary small">
              Download {{ result.filename }}
            </a>
          </div>
        </div>

        <!-- Unknown Response Type -->
        <div v-else class="result-box unknown">
          <p>Response received but format is unknown:</p>
          <pre>{{ result }}</pre>
        </div>

        <!-- Response Metadata -->
        <div class="response-meta">
          <span class="response-type">Type: {{ resultType }}</span>
          <span v-if="responseSize" class="response-size">Size: {{ responseSize }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import * as funcApi from '../../api/function.js'
import CodeMirror from "codemirror-editor-vue3";
import FormTable from '../../components/FormTable.vue';
import "codemirror/mode/javascript/javascript.js";

export default {
  name: 'TestFunctionModal',
  props: {
    script: Object
  },
  data() {
    return {
      activeTab: 'json',
      payload: '',
      formRows: [],
      isLoading: false,
      errorData: null,
      store,
      result: null,
      resultType: null,
      responseSize: null,
    }
  },
  mounted() {
    // if (this.function.input) {
    //   this.loadExampleInput(this.function.input)
    // }
  },
  methods: {
    async attemptTestFunction() {
      if (this.isLoading) return;

      try {
        this.errorData = null;
        this.isLoading = true;
        this.result = '';

        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        })

        // Invoke the function
        let response;
        if (this.activeTab === 'json') {
          const jsonBody = this.payload.trim().length > 0 ? JSON.parse(this.payload) : {};
          response = await funcApi.invokeFunctionJson(this.script.id, jsonBody);
        } else {
          const formData = new FormData();
          this.formRows.forEach(row => {
            formData.append(row.label, row.value);
          });
          response = await funcApi.invokeFunctionForm(this.script.id, formData);
        }

        // Handle errors
        if (response.status == 500) {
          const errorJson = await response.json();
          this.errorData = errorJson.error;
          return;
        }

        // Handle successful response
        await this.handleResponse(response)

      } catch (err) {
        this.errorData = {
          name: 'Failed to invoke function',
          message: err.message
        };
      } finally {
        this.isLoading = false;
      }
    },
    async handleResponse(response) {
      // Get content type from response headers
      const contentType = response.headers.get('content-type');
      const contentLength = response.headers.get('content-length');

      // Set response size if available
      if (contentLength) {
        this.responseSize = this.formatSize(parseInt(contentLength));
      }

      // Handle different content types
      if (contentType.includes('application/json')) {
        this.result = await response.json();
        this.resultType = 'json';
      }
      else if (contentType.includes('text/plain')) {
        this.result = await response.text();
        this.resultType = 'text';
      }
      else if (contentType.includes('text/html')) {
        this.result = await response.text();
        this.resultType = 'html';
      }
      else if (contentType.includes('image/')) {
        const blob = await response.blob();
        this.result = URL.createObjectURL(blob);
        this.resultType = 'image';
      }
      else if (contentType.includes('application/octet-stream') ||
        contentType.includes('application/pdf') ||
        contentType.includes('application/zip')) {
        const blob = await response.blob();
        const filename = this.getFilenameFromResponse(response) || 'download';
        this.result = {
          url: URL.createObjectURL(blob),
          filename: filename
        };
        this.resultType = 'file';
      }
      else {
        // Unknown type - try to get as text
        this.result = await response.text();
        this.resultType = 'unknown';
      }
    },
    getFilenameFromResponse(response) {
      const disposition = response.headers.get('content-disposition');
      if (disposition && disposition.includes('filename=')) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches && matches[1]) {
          return matches[1].replace(/['"]/g, '');
        }
      }
      return null;
    },
    formatSize(bytes) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    },
    handleUpdatePayload(value) {
      this.payload = value;
    },
    handleFormUpdate(rows) {
      this.formRows = rows;
    },
    loadExampleInput(input) {
      // Check if input starts with "JSON"
      if (input.startsWith("JSON")) {
        // Get everything after "JSON" and save to this.payload
        this.payload = input.substring(4).trim();
        this.activeTab = 'json';
        return;
      }

      // Handle FORM format
      if (input.startsWith("FORM")) {
        const rows = input.substring(4).trim().split('\n');
        this.activeTab = 'form'
        this.formRows = rows.map(row => {
          // Remove leading dash and spaces, and trim
          let cleanRow = row.replace(/^-/, '').trim();

          // Split by '/' and clean each part
          const parts = cleanRow.split('/').map(part => {
            // Remove quotes and trim
            return part.replace(/"/g, '').trim();
          });

          const [label, type, value] = parts;

          // For file type, return empty object as value
          if (type === 'file') {
            return {
              label,
              type,
              value: {}
            };
          }

          // For other types, return the value as is
          return {
            label,
            type,
            value
          };
        });
      }
    }
  },
  components: {
    CodeMirror,
    FormTable
  }
}
</script>

<style scoped>
/* Card modifications */
.card {
  gap: var(--space-md);
  padding: var(--space-lg);
  background: var(--background-secondary);
  border: 1px solid var(--border-primary);
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.card-header {
  padding-right: var(--space-lg); /* Space for close button */
}

/* Tabs styling */
.tabs {
  display: flex;
  border-bottom: 1px solid var(--border-primary);
  margin-bottom: var(--space-md);
  gap: var(--space-md);
}

.tab-button {
  padding: var(--space-sm) 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: var(--font-size-base);
  border-bottom: 2px solid transparent;
  transition: var(--transition-base);
  color: var(--text-tertiary);
  min-width: 120px;
}

.tab-button:hover {
  color: var(--text-secondary);
}

.tab-button.active {
  border-bottom-color: var(--primary-400);
  color: var(--primary-400);
}

/* Result container styling */
.result-container {
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  overflow: hidden;
  background: var(--background-tertiary);
}

.result-box {
  padding: var(--space-md);
  max-height: 400px;
  overflow-y: auto;
  background: var(--background-tertiary);
  font-family: 'Untitled Sans', monospace;
  white-space: pre-wrap;
  color: var(--text-primary);
  border: none;
  margin: 0;
}

.result-box.code pre {
  margin: 0;
  font-family: 'Untitled Sans', monospace;
}

.result-box.image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-lg);
  background: var(--background-primary);
}

.result-box.image img {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.result-box.file {
  padding: var(--space-lg);
}

.file-info {
  display: flex;
  align-items: center;
  gap: var(--space-md);
  color: var(--text-primary);
}

.response-meta {
  padding: var(--space-sm) var(--space-md);
  background: var(--background-secondary);
  border-top: 1px solid var(--border-primary);
  font-size: var(--font-size-sm);
  color: var(--text-secondary);
  display: flex;
  gap: var(--space-md);
}

/* Error styling */
.error-container {
  margin: 0;
  padding: var(--space-md);
  border-radius: 2px;
  background: color-mix(in srgb, var(--error-500) 8%, var(--background-tertiary));
  border: 1px solid var(--error-500);
}

.error-header {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  margin-bottom: var(--space-sm);
}

.error-icon {
  font-size: var(--font-size-xl);
}

.error-title {
  font-weight: bold;
  color: var(--error-500);
}

.error-message {
  color: var(--text-primary);
  margin-bottom: var(--space-md);
  line-height: 1.5;
}

.error-stack {
  background: var(--background-secondary);
  border-radius: 2px;
  padding: var(--space-md);
}

.stack-header {
  color: var(--error-500);
  font-size: var(--font-size-sm);
  margin-bottom: var(--space-sm);
  font-weight: bold;
}

.error-stack pre {
  margin: 0;
  font-family: 'Untitled Sans', monospace;
  font-size: var(--font-size-sm);
  white-space: pre-wrap;
  word-break: break-all;
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Close button */
.close-modal-icon {
  opacity: 0.7;
  transition: var(--transition-fast);
  cursor: pointer;
}

.close-modal-icon:hover {
  opacity: 1;
}
</style>