<template>
  <div style="display: none">Loading...</div>
</template>

<script>
import * as pageApi from '@/api/page.js'

export default {
  name: 'RenderedPageView',
  methods: {
    injectContent(html) {
      // Create a temporary container to parse HTML
      const temp = document.createElement('div')
      temp.innerHTML = html

      // Find all scripts in the HTML
      const scripts = temp.getElementsByTagName('script')
      const scriptsArray = Array.from(scripts)

      // Remove scripts from the temp container
      scriptsArray.forEach(oldScript => {
        oldScript.remove()
      })

      // Replace entire document HTML except scripts
      document.documentElement.innerHTML = temp.innerHTML

      // Import bootstrap
      const bootstrapStyle = document.createElement('link');
      bootstrapStyle.href = '/bootstrap/bootstrap.min.css';
      bootstrapStyle.rel = 'stylesheet'
      document.head.appendChild(bootstrapStyle);

      const bootstrapScript = document.createElement('script');
      bootstrapStyle.src = '/bootstrap/bootstrap.min.js';
      document.body.appendChild(bootstrapScript);

      // Re-add scripts one by one
      scriptsArray.forEach(oldScript => {
        const newScript = document.createElement('script')

        // Copy all attributes
        Array.from(oldScript.attributes).forEach(attr => {
          newScript.setAttribute(attr.name, attr.value)
        })

        // Copy inline script content
        newScript.textContent = oldScript.textContent

        // Add the new script to the document
        document.body.appendChild(newScript)
      })
    }
  },
  async created() {
    try {
      const id = this.$route.params.id
      const response = await pageApi.fetchPage(id)

      // We need to wait for the DOM to be ready
      this.$nextTick(() => {
        this.injectContent(response)
      })
    } catch (err) {
      if (err.message == 'No Permission') {
        this.$router.push('/login?ref=' + window.location.href)
        return;
      }

      alert(err.message)
    }
  }
}
</script>