<template>
  <div class="button-container">
    <div class="button secondary small" @click.prevent.stop="openDropdown()">
      <span>{{ label }}</span>
      <i class="arrow"></i>
    </div>
    <transition name="dropdown" mode="out-in">
      <div class="dropdown-list" v-if="isOpen">
        <div class="dropdown-item" v-for="option of options" :key="option" @click="onClickItem(option)">
          <span>{{ option.label }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DropdownButton',
  props: {
    label: String,
    options: Array
  },
  data() {
    return {
      isOpen: false
    }
  },
  created() {
    window.addEventListener('click', this.handleClick);
  },
  unmounted() {
    window.removeEventListener('click', this.handleClick);
  },
  methods: {
    openDropdown() {
      this.isOpen = true;
    },
    handleClick(event) {
      const el = this.$el;
      if (!el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    onClickItem(option) {
      if (option.action == undefined) return;
      this.isOpen = false;
      option.action();
    }
  }
}
</script>

<style scoped>
.button-container {
  position: relative;
}

.button {
  display: flex;
  gap: var(--space-sm);
}

.arrow {
  border: solid var(--text-primary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 2px;
}

.dropdown-list {
  position: absolute;
  top: calc(100% + var(--space-xs));
  left: 0;
  min-width: 100%;
  background: var(--background-primary);
  border: 1px solid var(--border-primary);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  overflow: hidden;
  border-radius: 2px;
}

.dropdown-item {
  padding: var(--space-sm);
  cursor: pointer;
  text-wrap: nowrap;
  color: var(--text-primary);
  transition: var(--transition-fast);
}

.dropdown-item:not(:first-child) {
  border-top: 1px solid var(--border-primary);
}

.dropdown-item:hover {
  background: var(--background-secondary);
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: var(--transition-base);
  transform-origin: top;
  transform: none;
  opacity: 1;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
  transform: scale(0.95);
}
</style>