<template>
  <div class="card" style="max-width: 500px;">
    <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="card-header">
      <h1>Create {{ scriptTypeDisplay }}</h1>
      <p>Please provide a name that you want to use</p>
    </div>
    <div class="card-body">
      <form action="" class="form" @submit.prevent="attemptCreateScript">
        <!-- Email -->
        <div class="form-group">
          <input type="text" id="name" class="form-control" placeholder=" " v-model="name">
          <label for="name" class="form-label">Script Name</label>
        </div>
        <input type="submit" hidden>
      </form>
    </div>
    <div class="card-footer">
      <button class="button primary" @click="attemptCreateScript">
        <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
        <span v-else>Create Script</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as scriptApi from '@/api/script.js'

export default {
  name: 'CreateScriptModal',
  props: {
    scriptTypeDisplay: String,
    scriptType: String
  },
  data() {
    return {
      type: '',
      name: '',
      description: '',
      isLoading: false,
      error: ''
    }
  },
  methods: {
    async attemptCreateScript() {
      if (this.isLoading) return;

      try {
        this.error = '';
        this.isLoading = true;

        const appId = this.$route.params.app;

        // Create script
        const script = await scriptApi.createScript({
          appId: appId,
          scriptName: this.name,
          scriptType: this.scriptType
        })

        this.$emit('onScriptCreated', script);

      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    }
  },
  emits: ['onScriptCreated']
}
</script>

<style scoped></style>