<template>
  <div class="script-sidebar" ref="sidebar">
    <div class="resize-handle"></div>
    <div class="script-header">
      <h3>{{ title }}</h3>
      <dropdown-button label="Create New" :options="options" />
    </div>
    <div class="script-item-list" @dragover.prevent @drop.prevent="onDropOutsideFolder($event)">
      <!-- Folder list -->
      <div class="folder-item" @dragover.prevent="onDragOver($event, folder)" @dragenter.prevent="onDragEnter($event)"
        @dragleave.prevent="onDragLeave($event)" @drop.prevent="onDragDrop($event, folder)"
        v-for="folder of getFolders()" :key="folder.id">
        <div class="folder-info" @click.prevent="toggleFolder(folder.id)">
          <img :src="openFolders.has(folder.id) ? '/icons/folder-open.png' : '/icons/folder.png'" alt="Folder Icon"
            class="item-icon">
          <p class="folder-name truncate">{{ folder.name }}</p>
          <img src="@/assets/icons/close.png" alt="Delete" class="delete-icon"
            @click.prevent.stop="$emit('openModal', 'delete-folder', { folder })">
        </div>
        <div class="folder-items" v-show="openFolders.has(folder.id)">
          <!-- Root items -->
          <router-link class="script-item" draggable="true" @dragstart="onDragStart($event, script)"
            v-for="script of folder.scripts" :key="script.id" :to="script.path">
            <img :src="icon" alt="Script Icon" class="item-icon">
            <p class="script-name truncate">{{ script.name }}</p>
            <img src="@/assets/icons/close.png" alt="Delete" class="delete-icon"
              @click.prevent.stop="$emit('openModal', 'delete-script', { script })">
          </router-link>
        </div>
      </div>
      <!-- Root items -->
      <router-link class="script-item" draggable="true" @dragstart="onDragStart($event, script)"
        v-for="script of getScripts()" :key="script.id" :to="script.path">
        <img :src="icon" alt="Script Icon" class="item-icon">
        <p class="script-name truncate">{{ script.name }}</p>
        <img src="@/assets/icons/close.png" alt="Delete" class="delete-icon"
          @click.prevent.stop="$emit('openModal', 'delete-script', { script })">
      </router-link>
    </div>
  </div>
</template>

<script>
import DropdownButton from '../DropdownButton.vue'

export default {
  name: 'ScriptSidebar',
  props: {
    title: String,
    options: Array,
    scripts: Array,
    folders: Array,
    icon: String
  },
  data() {
    return {
      openFolders: new Set(),
      sidebarWidth: 400
    }
  },
  mounted() {
    this.initializeResize();
  },
  methods: {
    initializeResize() {
      const handle = this.$refs.sidebar.querySelector('.resize-handle');
      let startX, startWidth;

      const startResize = (e) => {
        e.preventDefault();
        startX = e.clientX;
        startWidth = this.$refs.sidebar.offsetWidth;
        document.addEventListener('mousemove', resize);
        document.addEventListener('mouseup', stopResize);
      };

      const resize = (e) => {
        const width = startWidth + (e.clientX - startX);
        // Set min and max width constraints
        this.$refs.sidebar.style.width = `${Math.min(Math.max(width, 200), 800)}px`;
      };

      const stopResize = () => {
        document.removeEventListener('mousemove', resize);
        document.removeEventListener('mouseup', stopResize);
      };

      handle.addEventListener('mousedown', startResize);
    },
    // ... rest of the existing methods remain the same
    getFolders() {
      return this.folders.map(folder => {
        return {
          ...folder,
          scripts: this.scripts.filter(script => script.folderId == folder.id)
        }
      })
    },
    getScripts() {
      return this.scripts.filter(script => script.folderId == null)
    },
    onDragStart(event, script) {
      event.dataTransfer.setData('scriptId', script.id);
    },
    onDragOver(event, folder) {
      const scriptId = event.dataTransfer.getData('scriptId');
      const script = this.scripts.find(s => s.id === scriptId);
      if (script && script.folderId === folder.id) {
        event.dataTransfer.dropEffect = 'none';
        return;
      }
      event.dataTransfer.dropEffect = 'move';
    },
    onDragLeave(event) {
      if (!event.relatedTarget?.closest('.folder-item')) {
        const folderEl = event.target.closest('.folder-item');
        if (folderEl) {
          folderEl.classList.remove('drag-over');
        }
      }
    },
    onDragEnter(event) {
      document.querySelectorAll('.folder-item').forEach(el => {
        el.classList.remove('drag-over');
      });
      const folderEl = event.target.closest('.folder-item');
      if (folderEl) {
        folderEl.classList.add('drag-over');
      }
    },
    onDragDrop(event, folder) {
      const folderEl = event.target.closest('.folder-item');
      if (folderEl) {
        folderEl.classList.remove('drag-over');
      }
      const scriptId = event.dataTransfer.getData('scriptId');
      this.$emit('onMoveScriptToFolder', scriptId, folder.id);
    },
    onDropOutsideFolder(event) {
      if (event.target.className === 'script-item-list') {
        const scriptId = event.dataTransfer.getData('scriptId');
        this.$emit('onMoveScriptToFolder', scriptId);
      }
    },
    toggleFolder(folderId) {
      if (this.openFolders.has(folderId)) {
        this.openFolders.delete(folderId);
      } else {
        this.openFolders.add(folderId);
      }
    }
  },
  emits: ['openModal', 'onMoveScriptToFolder'],
  components: { DropdownButton }
}
</script>

<style scoped>
.script-sidebar {
  flex-shrink: 0;
  width: 400px;
  height: 100%;
  background: var(--background-primary);
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-right: 1px solid var(--border-primary);
  position: relative;
}

.resize-handle {
  position: absolute;
  right: -3px;
  top: 0;
  width: 6px;
  height: 100%;
  cursor: ew-resize;
  background: transparent;
  z-index: 11;
}

.resize-handle:hover {
  background: var(--primary-400);
}

/* Add truncate utility class */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Rest of the styles remain the same */
.script-header {
  padding: var(--space-md);
  border-bottom: 1px solid var(--border-primary);
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.script-item-list {
  padding: var(--space-md);
  height: 1px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.folder-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2px;
}

.folder-item.drag-over {
  background: var(--gray-800);
}

.folder-info {
  width: 100%;
  padding: var(--space-sm);
  display: flex;
  gap: var(--space-sm);
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  transition: var(--transition-base);
}

.folder-info:hover {
  background: var(--gray-800);
}

.folder-name {
  flex: 1;
  color: var(--text-primary);
  font-size: var(--font-size-base);
  min-width: 0; /* Enable truncation in flex container */
}

.folder-items {
  margin-left: var(--space-md);
  width: calc(100% - var(--space-md));
  border-left: 1px solid var(--gray-700);
  padding-left: var(--space-xs);
}

.script-item {
  width: 100%;
  padding: var(--space-sm);
  display: flex;
  gap: var(--space-sm);
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--text-primary);
  border-radius: 2px;
  transition: var(--transition-base);
}

.script-item:hover {
  background: var(--gray-800);
}

.script-item.is-active-exact {
  background: var(--gray-800);
  border-left: 2px solid var(--primary-400);
  padding-left: calc(var(--space-sm) - 2px);
}

.script-name {
  flex: 1;
  font-size: var(--font-size-base);
  color: var(--text-primary);
  min-width: 0; /* Enable truncation in flex container */
}

.item-icon {
  width: 30px;
  height: 30px;
  display: block;
  flex-shrink: 0;
}

.delete-icon {
  width: 16px;
  height: 16px;
  display: block;
  opacity: 0.5;
  transition: var(--transition-fast);
  flex-shrink: 0;
}

.delete-icon:hover {
  opacity: 1;
}
</style>