<template>
  <nav>
    <div class="link-items">
      <router-link 
        class="link-item" 
        to="/apps" 
      >
        <img src="/icons/app.png" alt="Apps" class="nav-icon" />
        <span class="nav-label">Apps</span>
      </router-link>
      <router-link 
        class="link-item" 
        :to="`/apps/${$route.params.app}/pages`" 
        :data-active="isRouteActive('pages')"
      >
        <img src="/icons/page.png" alt="Pages" class="nav-icon" />
        <span class="nav-label">Pages</span>
      </router-link>
      <router-link 
        class="link-item" 
        :to="`/apps/${$route.params.app}/functions`" 
        :data-active="isRouteActive('functions')"
      >
        <img src="/icons/function.png" alt="Functions" class="nav-icon" />
        <span class="nav-label">Funcs</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationSidebar',
  methods: {
    isRouteActive(name) {
      const path = this.$route.path;
      return path.includes(name);
    }
  }
}
</script>

<style scoped>
nav {
  z-index: 100;
  width: 90px;
  height: 100%;
  background: var(--background-primary);
  border-right: 1px solid var(--border-primary);
  padding: var(--space-sm);
  display: flex;
  flex-direction: column;
}

.link-items {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
}

.link-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-xs);
  text-decoration: none;
  color: var(--text-tertiary);
  padding: var(--space-sm);
  border-radius: 2px;
  transition: var(--transition-base);
}

.link-item:hover {
  color: var(--text-secondary);
  background: var(--background-secondary);
}

.link-item[data-active="true"] {
  color: var(--primary-400);
  background: var(--background-secondary);
}

.nav-icon {
  width: 24px;
  height: 24px;
  display: block;
  opacity: 0.7;
  transition: var(--transition-base);
}

.link-item:hover .nav-icon {
  opacity: 0.9;
}

.link-item[data-active="true"] .nav-icon {
  opacity: 1;
}

.nav-label {
  text-align: center;
  line-height: 1.2;
}
</style>