<template>
  <div class="card" style="max-width: 500px;">
    <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="card-header">
      <h1>{{ app.name }}</h1>
      <p>{{ app.description }}</p>
    </div>
    <div class="card-body">
      <div class="app-image-container">
        <div class="app-image"></div>
      </div>
    </div>
    <div class="card-footer">
      <div class="button-list">
        <router-link class="button secondary" :to="`/apps/${app.id}/pages`">Edit</router-link>
        <button class="button primary" @click="openApp">Open</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInfoModal',
  props: {
    app: Object
  }
}
</script>

<style scoped>
.button-list {
  display: flex;
  gap: 10px;
}

.app-image-container {
  width: 100%;
  padding: 20px;
  background: var(--fill-med);
  border-radius: 10px;
}

.app-image {
  width: 100%;
  aspect-ratio: 2;
  background-image: url('@/assets/icons/app.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>