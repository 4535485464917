<template>
  <div class="script-editor">
    <!-- Editor Header -->
    <div class="script-editor-header">
      <div class="script-info">
        <h3>{{ scriptInfo.name }}</h3>
      </div>
      <div class="script-actions">
        <button class="button secondary small" @click.prevent="attemptSaveScript" v-if="isChanged()">
          <img src="@/assets/loading.svg" alt="Loading" class="loading-icon" v-if="isSaving">
          <span v-else>Save Changes</span>
        </button>
        <slot name="buttons" v-else></slot>
      </div>
    </div>
    <!-- Editor Body -->
    <div class="script-editor-body">
      <!-- eslint-disable-next-line vue/no-v-model-argument -->
      <code-mirror v-model:value="content" :options="editorOptions" @input="handleContentUpdated" />
      <!-- Toolbar -->
      <script-toolbar 
        :script-info="scriptInfo" 
        :script-type-display="scriptTypeDisplay" 
        @on-script-updated="handleScriptUpdated" 
      />
    </div>
  </div>
</template>

<script>
import * as scriptApi from '@/api/script.js'
import CodeMirror from "codemirror-editor-vue3";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import ScriptToolbar from './ScriptToolbar.vue';
import '@/assets/codemirror-dark-theme.css'

export default {
  name: 'ScriptEditor',
  props: {
    scriptInfo: Object,
    language: String,
    scriptTypeDisplay: String
  },
  computed: {
    editorOptions() {
      return {
        mode: this.language,
        theme: 'dark',
        lineNumbers: true,
        line: true,
        styleActiveLine: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        tabSize: 2,
        indentUnit: 2,
        indentWithTabs: false,
        lineWrapping: false,
      }
    }
  },
  data() {
    return {
      content: '',
      isSaving: false
    }
  },
  watch: {
    scriptInfo: {
      handler(newScript) {
        this.content = newScript.content;
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    async attemptSaveScript() {
      try {
        this.isSaving = true;

        const script = await scriptApi.updateScript({
          scriptId: this.scriptInfo.id,
          content: this.content
        })

        this.$emit('onScriptUpdated', script)

      } catch (err) {
        alert(err.message);
      } finally {
        this.isSaving = false;
      }
    },
    handleContentUpdated(content) {
      this.content = content;
    },
    handleScriptUpdated(script) {
      console.info(script);
      this.$emit('onScriptUpdated', script)
    },
    isChanged() {
      return this.content != this.scriptInfo.content
    }
  },
  components: { CodeMirror, ScriptToolbar },
  emits: ['openModal', 'onScriptUpdated']
}
</script>

<style scoped>
.script-editor {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: var(--background-primary);
}

.script-editor-header {
  padding: var(--space-md);
  background: var(--background-primary);
  border-bottom: 1px solid var(--border-primary);
  display: flex;
  align-items: center;
  min-height: 90px;
  gap: var(--space-md);
}

.script-editor-body {
  height: 1px;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.script-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.script-info h3 {
  color: var(--text-primary);
  font-size: var(--font-size-xl);
  line-height: 1.2;
}

.script-actions {
  display: flex;
  align-items: center;
  gap: var(--space-sm);
}

/* Loading icon styles */
.loading-icon {
  display: block;
  height: 24px;
}
</style>