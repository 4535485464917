<template>
  <div class="script-toolbar">
    <transition name="slide" mode="out-in">
      <div class="tool-list" v-if="!activeTool">
        <div class="tool-item" @click="openTool('generate')">
          <img src="@/assets/icons/magic.png" alt="Generate">
        </div>
        <div class="tool-item" @click="openTool('edit')">
          <img src="@/assets/icons/edit.png" alt="Edit">
        </div>
        <div class="tool-item" @click="openTool('history')">
          <img src="@/assets/icons/history.png" alt="History">
        </div>
      </div>
      <!-- Generate tool -->
      <div class="tool-pane card" v-else-if="activeTool == 'generate'">
        <div class="card-header">
          <h3>Generate {{ scriptTypeDisplay }}</h3>
          <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon" @click="closeTool()" />
        </div>
        <div class="tool-pane-body">
          <div class="form-group">
            <textarea 
              type="text" 
              id="function-input" 
              class="form-control" 
              placeholder=" " 
              v-model="description"
              rows="1" 
              @input="handleResize" 
            />
            <label for="function-input" class="form-label">{{ scriptTypeDisplay }} description</label>
          </div>
        </div>
        <div class="card-footer">
          <button class="button" @click="attemptGenerateScript">
            <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
            <span v-else>Run Model</span>
          </button>
        </div>
      </div>
      <!-- Edit tool -->
      <div class="tool-pane card" v-else-if="activeTool == 'edit'">
        <div class="card-header">
          <h3>Edit {{ scriptTypeDisplay }}</h3>
          <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon" @click="closeTool()" />
        </div>
        <div class="tool-pane-body">
          <div class="form-group">
            <textarea 
              type="text" 
              id="function-input" 
              class="form-control" 
              placeholder=" " 
              v-model="adjustment"
              rows="1" 
              @input="handleResize" 
            />
            <label for="function-input" class="form-label">Request adjustment</label>
          </div>
        </div>
        <div class="card-footer">
          <button class="button" @click="attemptAdjustScript">
            <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
            <span v-else>Run Model</span>
          </button>
        </div>
      </div>
      <!-- History tool -->
      <div class="tool-pane card" v-else-if="activeTool == 'history'">
        <div class="card-header">
          <h3>Script History</h3>
          <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon" @click="closeTool()" />
        </div>
        <div class="tool-pane-body">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as scriptApi from '@/api/script.js'

export default {
  name: 'ScriptToolbar',
  props: {
    scriptInfo: Object,
    scriptTypeDisplay: String
  },
  data() {
    return {
      activeTool: '',
      description: '',
      adjustment: '',
      isLoading: false
    }
  },
  methods: {
    async attemptGenerateScript() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;

        const updatedScript = await scriptApi.aiGenerate({
          scriptId: this.scriptInfo.id,
          description: this.description
        })

        this.$emit('onScriptUpdated', updatedScript);

        this.description = '';
        this.isLoading = false;
        this.closeTool();

      } catch (err) {
        alert('Failed: ' + err.message);
        this.isLoading = false;
      }
    },
    async attemptAdjustScript() {
      if (this.isLoading) return;

      try {
        this.isLoading = true;

        const updatedScript = await scriptApi.aiAdjust({
          scriptId: this.scriptInfo.id,
          adjustment: this.adjustment
        })

        this.$emit('onScriptUpdated', updatedScript);

        this.adjustment = '';
        this.isLoading = false;
        this.closeTool();

      } catch (err) {
        alert('Failed: ' + err.message);
        this.isLoading = false;
      }
    },
    openTool(tool) {
      if (this.isLoading) return;
      this.activeTool = tool;
    },
    closeTool() {
      if (this.isLoading) return;
      this.activeTool = '';
    },
    handleResize(event) {
      const target = event.target;
      target.style.height = '1px';
      target.style.height = `${target.scrollHeight}px`
    },
  },
  emits: ['onScriptUpdated']
}
</script>

<style scoped>
.script-toolbar {
  position: absolute;
  top: var(--space-sm);
  right: var(--space-sm);
  width: 0;
  height: calc(100% - var(--space-md));
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.tool-list {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
}

.tool-item {
  padding: var(--space-sm);
  background: var(--background-primary);
  border-radius: 2px;
  border: 1px solid var(--border-primary);
  cursor: pointer;
  transition: var(--transition-base);
}

.tool-item:hover {
  background: var(--background-secondary);
}

.tool-item img {
  width: 30px;
  height: 30px;
  display: block;
}

.tool-pane {
  position: relative;
  flex-shrink: 0;
  width: 500px;
  max-height: 100%;
  background: var(--background-secondary);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.tool-pane-header {
  padding: var(--space-md);
  padding-bottom: var(--space-sm);
  border-bottom: 1px solid var(--border-primary);
}

.tool-pane-header h3 {
  color: var(--text-primary);
  font-size: var(--font-size-xl);
}

.tool-pane-footer {
  padding: var(--space-md);
  padding-top: var(--space-sm);
  border-top: 1px solid var(--border-primary);
}

.tool-pane-body {
  height: 1px;
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin: -20px;
}

.close-modal-icon {
  position: absolute;
  right: var(--space-md);
  top: var(--space-md);
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.5;
  transition: var(--transition-fast);
}

.close-modal-icon:hover {
  opacity: 1;
}

/* Transition styles */
.slide-enter-active,
.slide-leave-active {
  transition: var(--transition-base);
  transform: none;
  opacity: 1;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(10px);
}
</style>