<template>
  <div class="card" style="max-width: 500px;">
    <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="card-header">
      <h1>Create App</h1>
      <p>Please provide a name and description for this app.</p>
    </div>
    <div class="card-body">
      <form action="" class="form" @submit.prevent="attemptCreateApp">
        <!-- Email -->
        <div class="form-group">
          <input type="text" id="email" class="form-control" placeholder=" " v-model="name">
          <label for="email" class="form-label">App Name</label>
        </div>
        <div class="form-group">
          <input type="text" id="email" class="form-control" placeholder=" " v-model="description">
          <label for="email" class="form-label">App Description</label>
        </div>
        <input type="submit" hidden>
      </form>
    </div>
    <div class="card-footer">
      <button class="button primary" @click="attemptCreateApp">
        <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
        <span v-else>Create</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as appApi from '@/api/app.js'

export default {
  name: 'CreateAppModal',
  data() {
    return {
      name: '',
      description: '',
      isLoading: false,
      error: ''
    }
  },
  methods: {
    async attemptCreateApp() {
      if (this.isLoading) return;

      try {
        this.error = '';
        this.isLoading = true;

        // One second delay
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        })

        // Attempt to login
        const app = await appApi.createApp({
          appName: this.name,
          appDescription: this.description
        })

        this.$router.push(`/apps/${app.id}/pages`);

      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
</style>