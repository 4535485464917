import { createRouter, createWebHistory } from "vue-router";
import LoginView from "@/views/LoginView.vue";
import AppListView from "@/views/AppListView.vue";
import FunctionEditorView from "@/views/functions/FunctionEditorView.vue";
import PageEditorView from "@/views/pages/PageEditorView.vue";
import RenderedPageView from "@/views/RenderedPageView.vue";

const routes = [
  {
    path: '/',
    redirect: '/apps'
  },
  {
    name: 'Login',
    path: '/login',
    component: LoginView
  },
  {
    name: 'App List',
    path: '/apps',
    component: AppListView
  },
  {
    name: 'Rendered Page View',
    path: '/pages/:id',
    component: RenderedPageView
  },
  {
    name: 'App Functions',
    path: '/apps/:app/functions/:script?',
    component: FunctionEditorView
  },
  {
    name: 'App Pages',
    path: '/apps/:app/pages/:script?',
    component: PageEditorView
  }
]

const router = createRouter({
  routes: routes,
  history: createWebHistory(),
  linkExactActiveClass: 'is-active-exact'
})

export default router;