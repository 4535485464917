<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900');

.CodeMirror * {
  font-family: 'Source Code Pro', monospace;
  font-size: 16px;
}

.codemirror-container[data-readonly="true"] {
  opacity: 0.5;
}
</style>
