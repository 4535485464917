<template>
  <div class="card" style="max-width: 500px;">
    <img src="@/assets/icons/close.png" alt="Close Modal" class="close-modal-icon close-on-click" />
    <div class="card-header">
      <h1>Create Folder</h1>
      <p>Please provide a name and description for this folder.</p>
    </div>
    <div class="card-body">
      <form action="" class="form" @submit.prevent="attemptCreateFolder">
        <!-- Email -->
        <div class="form-group">
          <input type="text" id="function-path" class="form-control" placeholder=" " v-model="name">
          <label for="function-path" class="form-label">Folder Name</label>
        </div>
        <input type="submit" hidden>
      </form>
    </div>
    <div class="card-footer">
      <button class="button primary" @click="attemptCreateFolder">
        <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
        <span v-else>Create Folder</span>
      </button>
      <p class="error" v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import * as scriptApi from '@/api/script.js'

export default {
  name: 'CreateFolderModal',
  props: {
    folderType: String
  },
  data() {
    return {
      name: '',
      isLoading: false,
      error: ''
    }
  },
  methods: {
    async attemptCreateFolder() {
      if (this.isLoading) return;

      try {
        this.error = '';
        this.isLoading = true;

        const appId = this.$route.params.app;

        // Attempt to login
        const folder = await scriptApi.createFolder({
          appId: appId,
          folderName: this.name,
          folderType: this.folderType
        })

        this.$emit('onFolderCreated', folder);

      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    }
  },
  emits: ['onFolderCreated']
}
</script>

<style scoped></style>