import { postJson } from "./common"

export async function createPage({ appId, pageName, pageDescription }) {
  const resJson = await postJson('/page/create', { appId, pageName, pageDescription });
  if (!resJson.page) throw Error('Malformed response from backend');
  return resJson.page;
}

export async function updatePage({ pageId, content }) {
  const resJson = await postJson('/page/update', { pageId, content });
  if (!resJson.page) throw Error('Malformed response from backend');
  return resJson.page;
}

export async function revertPage({ pageId, changeId }) {
  const resJson = await postJson('/page/revert', { pageId, changeId });
  if (!resJson.page) throw Error('Malformed response from backend');
  return resJson.page;
}

export async function listPages(appId) {
  const resJson = await postJson('/page/list', { appId });
  if (!resJson.pages) throw Error('Malformed response from backend');
  return resJson.pages;
}

export async function pageInfo(pageId) {
  const resJson = await postJson('/page/info', { pageId });
  if (!resJson.page) throw Error('Malformed response from backend');
  return resJson.page;
}

export async function generatePage({ pageId, description }) {
  const resJson = await postJson('/page/ai-generate', { pageId, description });
  if (!resJson.page) throw Error('Malformed response from backend');
  return resJson.page;
}

export async function adjustPage({ pageId, content, adjustment }) {
  const resJson = await postJson('/page/ai-adjust', { pageId, content, adjustment });
  if (!resJson.page) throw Error('Malformed response from backend');
  return resJson.page;
}

export async function fetchPage(pageId) {
  const res = await fetch(process.env.VUE_APP_API + '/page/fetch/' + pageId, { credentials: 'include'} );
  if (res.status == 401) throw Error('No Permission');
  const text = await res.text();
  return text;
}

export async function deletePage({ pageId }) {
  await postJson('/page/delete', { pageId });
}