<template>
  <div class="full center bg-med">
    <div class="card">
      <div class="card-header">
        <h1>Account Login</h1>
        <p>Please log in using your credentials.</p>
      </div>
      <div class="card-body">
        <form action="" class="form" @submit.prevent="attemptLogin">
          <!-- Email -->
          <div class="form-group">
            <input type="text" id="email" class="form-control" placeholder=" " v-model="email">
            <label for="email" class="form-label">Email Address</label>
          </div>
          <!-- Password -->
          <div class="form-group">
            <input type="password" id="password" class="form-control" placeholder=" " v-model="password">
            <label for="password" class="form-label">Password</label>
          </div>
          <input type="submit" hidden>
        </form>
      </div>
      <div class="card-footer">
        <button class="button primary" @click="attemptLogin">
          <img v-if="isLoading" src="@/assets/loading.svg" alt="Loading" class="loading-icon">
          <span v-else>Login</span>
        </button>
        <p class="error" v-if="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as userApi from '@/api/user.js'

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      password: '',
      error: '',
      isLoading: false
    }
  },
  methods: {
    async attemptLogin() {

      if (this.isLoading) return;

      try {
        this.error = '';
        this.isLoading = true;

        // One second delay
        await new Promise((resolve) => {
          setTimeout(resolve, 500);
        })

        // Test the email
        if (!/(.+)@(.+)\.(.+)/.test(this.email)) {
          throw Error('Email is not valid format');
        }

        // Attempt to login
        await userApi.attemptLogin({
          userEmail: this.email,
          userPassword: this.password
        })

        const ref = this.$route.query.ref;
        if (ref) {
          window.location.replace(ref);
          return;
        }

        this.$router.push('/apps');

      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style scoped>
.card {
  max-width: 450px;
}
</style>