<template>
  <div class="full bg-med">
    <!-- navigation bar -->
    <navigation-sidebar />
    <!-- sidebar -->
    <script-sidebar title="Functions" icon="/icons/function.png" :options="sidebarOptions" :scripts="getScriptList()"
      :folders="folderList" @open-modal="openModal" @on-move-script-to-folder="attemptMoveScriptToFolder" />
    <!-- function editor -->
    <script-editor v-if="scriptInfo" ref="editor" :script-info="scriptInfo" :key="scriptInfo.id" script-type-display="Function" language="javascript"
      @open-modal="openModal" @on-script-updated="handleScriptUpdated">
      <!-- buttons -->
      <template v-slot:buttons>
        <button class="button small primary" @click.prevent="openModal('test-function')">Test Function</button>
      </template>
    </script-editor>
    <!-- Modals -->
    <transition name="modal-fade" mode="out-in">
      <div class="modal-background close-on-click" v-if="modal.open" @click="handleModalClick">
        <div class="modal-scroll close-on-click">
          <transition name="modal-pop" mode="out-in">
            <create-script-modal v-if="modal.id == 'create-script'" script-type-display="Function"
              script-type="FUNCTION" @on-script-created="handleScriptCreated" />
            <delete-script-modal v-else-if="modal.id == 'delete-script'" script-type-display="Function"
              script-type="FUNCTION" :script="modal.data.script" @on-script-deleted="handleScriptDeleted" />
            <create-folder-modal v-else-if="modal.id == 'create-folder'" folder-type="FUNCTION"
              @on-folder-created="handleFolderCreated" />
            <delete-folder-modal v-else-if="modal.id == 'delete-folder'" :folder="modal.data.folder"
              @on-form-deleted="handleFormDeleted" />
            <test-function-modal v-else-if="modal.id == 'test-function'" :script="scriptInfo" />
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as scriptApi from '@/api/script.js'
import ScriptSidebar from '@/components/scripts/ScriptSidebar.vue'
import ScriptEditor from '@/components/scripts/ScriptEditor.vue'
import NavigationSidebar from '@/components/NavigationSidebar.vue';

// Modals
import CreateScriptModal from '@/modals/scripts/CreateScriptModal.vue';
import DeleteScriptModal from '@/modals/scripts/DeleteScriptModal.vue';
import CreateFolderModal from '@/modals/scripts/CreateFolderModal.vue'
import DeleteFolderModal from '@/modals/scripts/DeleteFolderModal.vue';
import TestFunctionModal from '@/modals/functions/TestFunctionModal.vue';

export default {
  name: 'FunctionEditorView',
  data() {
    return {
      modal: {
        open: false,
        id: '',
        data: {}
      },

      sidebarOptions: [
        {
          label: 'Function',
          action: () => this.openModal('create-script')
        },
        {
          label: 'Folder',
          action: () => this.openModal('create-folder')
        }
      ],

      folderList: [],
      scriptList: [],
      isLoadingScriptList: false,

      scriptInfo: null,
      isLoadingScript: false,
      currentContent: ''
    }
  },
  mounted() {
    const appId = this.$route.params.app;
    this.attemptFetchScriptList(appId);
  },
  watch: {
    $route: {
      handler(newRoute) {
        const scriptId = newRoute.params.script;
        if (scriptId) {
          this.attemptFetchScript(scriptId);
        } else {
          this.scriptInfo = null;
        }
      },
      immediate: true
    }
  },
  methods: {
    async attemptFetchScriptList(appId) {
      try {
        this.isLoadingScriptList = true;

        const [scriptList, folderList] = await Promise.all([
          scriptApi.listScripts({ appId, scriptType: 'FUNCTION' }),
          scriptApi.listFolders({ appId, folderType: 'FUNCTION' })
        ])

        this.scriptList = scriptList;
        this.folderList = folderList;

      } catch (err) {
        alert('Failed to fetch script list: ' + err.message);
      } finally {
        this.isLoadingScriptList = false;
      }
    },
    async attemptFetchScript(scriptId) {
      try {
        this.isLoadingScript = true;
        this.scriptInfo = await scriptApi.scriptInfo({ scriptId });
      } catch (err) {
        alert('Failed to fetch script: ' + err.message);
      } finally {
        this.isLoadingScript = false;
      }
    },
    async attemptMoveScriptToFolder(scriptId, folderId) {
      this.scriptList = this.scriptList.map(script => {
        if (script.id == scriptId) {
          script.folderId = folderId;
        }
        return script;
      })

      try {
        await scriptApi.moveToFolder({ scriptId, folderId });
      } catch (err) {
        alert('Failed to move script: ' + err.message);
      }
    },
    openModal(id, data) {
      this.modal.open = true;

      setTimeout(() => {
        this.modal.id = id;
        this.modal.data = data;
      })
    },
    closeModal() {
      this.modal.id = '';

      setTimeout(() => {
        this.modal.open = false;
      });
    },
    handleModalClick(event) {
      const classList = event.target.classList;
      if (classList.contains('close-on-click')) {
        this.closeModal();
      }
    },
    getScriptList() {
      const appId = this.$route.params.app;

      return this.scriptList.map(script => {
        return {
          ...script,
          path: `/apps/${appId}/functions/${script.id}`
        }
      })
    },
    handleScriptCreated(script) {
      this.closeModal();

      this.scriptList.push(script);

      const appId = this.$route.params.app;
      this.$router.push(`/apps/${appId}/functions/${script.id}`)
    },
    handleScriptDeleted(scriptId) {
      this.closeModal();

      this.scriptList = this.scriptList.filter(script => {
        return script.id != scriptId;
      })

      if (this.scriptInfo && this.scriptInfo.id == scriptId) {
        const appId = this.$route.params.app;
        this.$router.push(`/apps/${appId}/functions`)
      }
    },
    handleScriptUpdated(script) {
      this.scriptInfo = script;
    },
    handleFolderCreated(folder) {
      this.closeModal();

      this.folderList.push(folder);
    },
    handleFormDeleted(folderId) {
      this.closeModal();

      this.folderList = this.folderList.filter(folder => {
        return folder.id != folderId;
      })

      this.scriptList = this.scriptList.filter(script => {
        return script.folderId != folderId;
      })

      if (this.scriptInfo && this.scriptInfo.folderId == folderId) {
        const appId = this.$route.params.app;
        this.$router.push(`/apps/${appId}/functions`)
      }
    }
  },
  components: { NavigationSidebar, ScriptSidebar, ScriptEditor, CreateScriptModal, TestFunctionModal, CreateFolderModal, DeleteScriptModal, DeleteFolderModal }
}
</script>

<style scoped>
.full {
  display: flex;
  flex-direction: row;
}
</style>