<template>
  <div class="form-table-container">
    <table class="form-table">
      <thead>
        <tr>
          <th>Label</th>
          <th>Type</th>
          <th>Value</th>
          <th width="48px"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in formRows" :key="index">
          <td>
            <input 
              type="text" 
              class="form-control"
              v-model="row.label" 
              placeholder="Enter label" 
              @input="emitUpdate" 
            />
          </td>
          <td>
            <select 
              class="form-control"
              v-model="row.type" 
              @change="emitUpdate"
            >
              <option value="text">Text</option>
              <option value="file">File</option>
            </select>
          </td>
          <td>
            <input 
              v-if="row.type === 'text'" 
              type="text" 
              class="form-control"
              v-model="row.value" 
              placeholder="Enter value"
              @input="emitUpdate" 
            />
            <input 
              v-else 
              type="file"
              class="form-control" 
              @change="(e) => handleFileChange(e, index)" 
            />
          </td>
          <td>
            <button 
              class="button small danger" 
              @click="removeRow(index)"
              type="button"
            >
              ✕
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <button 
      class="button secondary small" 
      @click="addRow"
      type="button"
    >
      Add Row
    </button>
  </div>
</template>

<script>
export default {
  name: 'FormTable',
  props: {
    value: Array
  },
  data() {
    return {
      formRows: [{
        label: '',
        type: 'text',
        value: null
      }]
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (!newVal) return;
        this.formRows = structuredClone(newVal);
      },
      immediate: true
    }
  },
  methods: {
    addRow() {
      this.formRows.push({
        label: '',
        type: 'text',
        value: null
      });
      this.emitUpdate();
    },
    removeRow(index) {
      this.formRows.splice(index, 1);
      this.emitUpdate();
    },
    handleFileChange(event, index) {
      const file = event.target.files[0];
      this.formRows[index].value = file;
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('update', this.formRows);
    },
  }
}
</script>


<style scoped>
.form-table-container {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
}

.form-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.form-table th {
  text-align: left;
  padding: var(--space-sm);
  font-weight: normal;
  color: var(--text-tertiary);
  font-size: var(--font-size-sm);
  background: var(--background-secondary);
  border-bottom: 1px solid var(--border-primary);
}

.form-table td {
  padding: var(--space-xs);
  border-bottom: 1px solid var(--border-primary);
  background: var(--background-primary);
}

/* Override form-control styles for table use */
.form-control {
  border: none;
  outline: none;
  padding: var(--space-sm);
  font-size: var(--font-size-base);
  background: transparent;
  width: 100%;
  height: 100%;
  color: var(--text-primary);
}

.form-control::placeholder {
  color: var(--text-tertiary);
}

.form-control:focus {
  background: var(--background-secondary);
}

/* Custom select styling */
select.form-control {
  cursor: pointer;
  appearance: none;
  padding-right: var(--space-lg);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right var(--space-sm) center;
  background-size: 16px;
}

select.form-control:focus {
  background-color: var(--background-secondary);
}

/* Custom file input styling */
input[type="file"].form-control {
  padding: var(--space-xs) var(--space-sm);
}

input[type="file"].form-control::-webkit-file-upload-button {
  visibility: hidden;
  display: none;
}

input[type="file"].form-control::before {
  content: 'Choose File';
  display: inline-block;
  padding: var(--space-xs) var(--space-sm);
  border: 1px solid var(--border-primary);
  border-radius: 2px;
  cursor: pointer;
  color: var(--text-primary);
  background: var(--background-secondary);
}

input[type="file"].form-control:hover::before {
  background: var(--background-tertiary);
}

/* Button modifications */
.button.small {
  padding: var(--space-xs) var(--space-sm);
  font-size: var(--font-size-sm);
}

.button.danger {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--error-500);
  color: var(--text-primary);
}

.button.danger:hover {
  filter: brightness(1.1);
}
</style>