import { postJson } from "./common"

export async function createFunction({ appId, scriptName }) {
  const resJson = await postJson('/script/create', {
    appId,
    scriptName,
    scriptType: 'FUNCTION'
  });
  if (!resJson.script) throw Error('Malformed response from backend');
  return resJson.script;
}

export async function updateFunction({ functionId, content }) {
  const resJson = await postJson('/function/update', { functionId, content });
  if (!resJson.function) throw Error('Malformed response from backend');
  return resJson.function;
}

export async function revertFunction({ functionId, changeId }) {
  const resJson = await postJson('/function/revert', { functionId, changeId });
  if (!resJson.function) throw Error('Malformed response from backend');
  return resJson.function;
}

export async function invokeFunctionJson(id, payload) {
  return await fetch(`${process.env.VUE_APP_API}/function/invoke/${id}`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });
}

export async function invokeFunctionForm(id, body) {
  return await fetch(`${process.env.VUE_APP_API}/function/invoke/${id}`, {
    credentials: 'include',
    method: 'POST',
    body: body
  });
}

export async function listFunctions(appId) {
  const resJson = await postJson('/function/list', { appId });
  if (!resJson.functions) throw Error('Malformed response from backend');
  return resJson.functions;
}

export async function functionInfo(functionId) {
  const resJson = await postJson('/function/info', { functionId });
  if (!resJson.function) throw Error('Malformed response from backend');
  return resJson.function;
}

export async function generateFunction({ functionId, description, input, output }) {
  const resJson = await postJson('/function/ai-generate', { functionId, description, input, output });
  if (!resJson.function) throw Error('Malformed response from backend');
  return resJson.function;
}

export async function adjustFunction({ functionId, content, adjustment }) {
  const resJson = await postJson('/function/ai-adjust', { functionId, content, adjustment });
  if (!resJson.function) throw Error('Malformed response from backend');
  return resJson.function;
}

export async function deleteFunction({ functionId }) {
  await postJson('/function/delete', { functionId });
}